import {
	ActionIcon,
	Button,
	Collapse,
	CopyButton,
	Drawer,
	Textarea as FormTextArea,
	Group,
	Paper,
	Popover,
	Stack,
	Table,
	Text,
	Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	Link,
	RichTextEditor,
	useRichTextEditorContext,
} from '@mantine/tiptap';
import {
	IconAi,
	IconAlphabetLatin,
	IconCopy,
	IconExternalLink,
	IconHtml,
	IconMarkdown,
	IconPencil,
	IconPhoto,
	IconRowInsertBottom,
	IconRowInsertTop,
	IconSourceCode,
	IconTag,
	IconTemplate,
} from '@tabler/icons-react';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Placeholder from '@tiptap/extension-placeholder';
import Underline from '@tiptap/extension-underline';
import { BubbleMenu, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm, useFormContext, useWatch } from 'react-hook-form';
import ReactMarkdown from 'react-markdown';
import { Markdown } from 'tiptap-markdown';

import { AdminContext } from '../../context/admin';
import { AiContext } from '../../context/ai';
import { RequestError } from '../../error/request';
import ExtensionSmall from '../../extensions/tiptap/small';
import ExtensionTag from '../../extensions/tiptap/tag';
import useMutate from '../../hooks/use_mutate';
import useRequest from '../../hooks/use_request';
import AiPrompts from '../ai/prompts';
import Form from '../form';
import Input from '../form/input';
import Select from '../form/select';
import Submit from '../form/submit';
import Textarea from '../form/textarea';
import Language from '../framework/language';
import Suspense from '../framework/suspense';
import useToast from '../framework/toast';

const DrawerAi = ({ data, template_id }) => {
	const [opened, { toggle }] = useDisclosure(false);

	return (
		<>
			<ReactMarkdown>{data.messages[0]}</ReactMarkdown>

			<Collapse in={opened} my="xs">
				<AiPrompts
					items={data.prompts}
					settings={data?.settings}
					template_id={template_id}
				/>
			</Collapse>

			<Group grow>
				<CopyButton value={data.messages[0]}>
					{({ copied, copy }) => (
						<Button
							color={copied ? 'green' : ''}
							leftSection={<IconCopy />}
							onClick={copy}
							variant="light"
						>
							{copied ? 'Copied' : 'Copy'}
						</Button>
					)}
				</CopyButton>
				<Button onClick={toggle} variant="light">
					Prompts
				</Button>
			</Group>
		</>
	);
};

const Editor = ({
	counter = false,
	defaultType,
	defaultValue,
	description,
	format = 'html',
	max,
	name,
	options = {},
	placeholder,
	required,
	rows,
}) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();
	const currentValue = useWatch({ name });

	const error = errors && errors[name] ? errors[name] : null;

	if (!name) return <></>;
	return (
		<Controller
			control={control}
			defaultValue={defaultValue}
			name={name}
			render={({ field }) => (
				<EditorHandler
					defaultType={defaultType}
					description={
						<>
							{description}
							{counter && (
								<>
									{description ? ' - ' : null}
									{currentValue?.length ?? 0} {max ? ` / ${max}` : ''}
								</>
							)}
						</>
					}
					error={error}
					format={format}
					max={max}
					options={options}
					placeholder={placeholder}
					required={required}
					rows={rows}
					{...field}
				/>
			)}
		/>
	);
};

const EditorBubbleMenu = ({ editor, options, templates }) => {
	if (!options?.ai) return;

	return (
		<BubbleMenu editor={editor} tippyOptions={{ placement: 'bottom' }}>
			<Paper bg="gray.0" shadow="xs">
				<InsertAIControl
					options={options.ai}
					quick={true}
					templates={templates}
				/>
			</Paper>
		</BubbleMenu>
	);
};

const EditorHandler = React.forwardRef(function EditorHandler(
	{
		defaultType = 'html',
		description,
		error,
		format,
		max,
		name,
		onBlur,
		onChange,
		options,
		placeholder,
		required,
		rows,
		value,
	},
	ref,
) {
	const editor = useEditor({
		content: value,
		extensions: [
			ExtensionSmall,
			ExtensionTag,
			Highlight,
			Image,
			Link.configure({
				HTMLAttributes: {
					rel: null,
					target: null,
				},
				openOnClick: false,
			}),
			Placeholder.configure({
				placeholder,
			}),
			StarterKit,
			Underline,
			format == 'markdown' ? Markdown : undefined,
		],
		onBlur({ editor: editor1 }) {
			if (format == 'markdown') {
				onChange(editor1.storage.markdown.getMarkdown());
			} else {
				onChange(editor1.getHTML());
			}
		},
	});
	const [initialized, setInitialized] = useState(false);
	const [type, setType] = useState(defaultType);

	const { data: templateResponse } = useRequest(
		options?.ai?.partner_id && type == 'editor' ? 'content_manager.list' : null,
		{
			params: {
				fields: [
					'destination',
					'id',
					'name',
					'template_variants.content',
					'template_variants.language_id',
				],
				filter: {
					destination: { $in: ['ai', 'text'] },
					partner_id: { $eq: options?.ai?.partner_id },
					type: { $eq: options?.ai?.type },
				},
				limit: 1000,
				model: 'Template',
				relations: 'all',
			},
		},
	);
	const templates = templateResponse?.data?.items;

	const onUpdate = (event) => {
		onChange(event);
		editor.commands.setContent(event.target.value);
	};

	useEffect(() => {
		if (editor && !initialized) {
			setInitialized(true);
		}
	}, [editor, initialized]);

	if (!initialized) return;
	return (
		<>
			{type == 'editor' && (
				<RichTextEditor editor={editor}>
					{templates?.length > 0 && (
						<EditorBubbleMenu
							editor={editor}
							options={options}
							templates={templates}
						/>
					)}
					<EditorToolbar
						format={format}
						options={options}
						setType={setType}
						templates={templates}
						type={type}
					/>
					<RichTextEditor.Content />
				</RichTextEditor>
			)}
			{type == 'html' && (
				<>
					<FormTextArea
						autosize
						description={description}
						error={error?.message}
						label={placeholder}
						maxLength={max}
						name={name}
						onBlur={onBlur}
						onChange={onUpdate}
						placeholder={placeholder}
						ref={ref}
						required={required}
						resize="vertical"
						rightSection={
							<SwitchEditor format={format} setType={setType} type={type} />
						}
						rows={rows}
						value={value}
					/>
				</>
			)}
		</>
	);
});

const EditorToolbar = ({ format, options, setType, templates, type }) => {
	return (
		<RichTextEditor.Toolbar sticky stickyOffset={46}>
			<RichTextEditor.ControlsGroup>
				<RichTextEditor.Bold />
				<RichTextEditor.Italic />
				{format == 'html' && (
					<>
						<RichTextEditor.Underline />
						<RichTextEditor.Strikethrough />
					</>
				)}
				<RichTextEditor.Highlight />
				<RichTextEditor.ClearFormatting />
				<RichTextEditor.Code />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
				<RichTextEditor.H1 />
				<RichTextEditor.H2 />
				<RichTextEditor.H3 />
				<RichTextEditor.H4 />
				<RichTextEditor.H5 />

				{format == 'html' && <InsertTextSmallControl />}
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
				<RichTextEditor.Blockquote />
				<RichTextEditor.Hr />
				<RichTextEditor.BulletList />
				<RichTextEditor.OrderedList />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
				<RichTextEditor.Link />
				<RichTextEditor.Unlink />
			</RichTextEditor.ControlsGroup>

			<RichTextEditor.ControlsGroup>
				<RichTextEditor.Undo />
				<RichTextEditor.Redo />
			</RichTextEditor.ControlsGroup>

			{options?.ai && templates?.length > 0 && (
				<RichTextEditor.ControlsGroup>
					<InsertLanguageControl />
					<Suspense>
						<InsertAIControl options={options.ai} templates={templates} />
					</Suspense>
					<InsertTextControl templates={templates} />
				</RichTextEditor.ControlsGroup>
			)}

			{format == 'html' && (
				<RichTextEditor.ControlsGroup>
					<InsertImageControl />
					<InsertTagControl />
				</RichTextEditor.ControlsGroup>
			)}

			<RichTextEditor.ControlsGroup>
				<SwitchEditor setType={setType} type={type} />
			</RichTextEditor.ControlsGroup>
		</RichTextEditor.Toolbar>
	);
};

const InsertAIControl = ({ options, quick = false, templates }) => {
	const { language } = useContext(AdminContext);
	const { options: aiOptions } = useContext(AiContext);
	const [drawerOpened, { close: drawerClose, open: drawerOpen }] =
		useDisclosure(false);
	const [popupOpened, { close: popupClose, open: popupOpen }] =
		useDisclosure(false);
	const formMethods = useForm();
	const { trigger } = useMutate('ai.query');
	const { editor } = useRichTextEditorContext();
	const [drawerData, setDrawerData] = useState();
	const [loading, setLoading] = useState();
	const { errorToast } = useToast();

	const { state, view } = editor;
	const { from, to } = view.state.selection;
	const selected_text = state.doc.textBetween(from, to, '');

	const onSubmit = async (values) => {
		setLoading(values);
		try {
			const items_tmp = Object.values(aiOptions);
			const result = await trigger({
				analysis_id: options.analysis_id,
				input: values.input,
				items: items_tmp,
				language: language.id,
				save: false,
				template_id: values.template_id,
			});
			if (result?.status == 'error') {
				throw new RequestError({ request: result });
			}

			if (result?.data?.messages?.length) {
				if (values.action == 'modal') {
					setDrawerData({
						data: result.data,
						template_id: values.template_id,
					});
					drawerOpen();
				} else if (values.action == 'insert_after') {
					editor?.commands.insertContentAt(
						to,
						'\n\n' + result.data.messages[0],
					);
				} else if (values.action == 'insert_before') {
					editor?.commands.insertContentAt(
						from,
						result.data.messages[0] + '\n\n',
					);
				} else {
					editor?.commands.insertContent(result.data.messages[0]);
				}
			}
		} catch (error) {
			errorToast({
				description: 'Unable to query the AI, please try again.',
				error,
				title: 'Error',
			});
		}

		popupClose();
		setLoading(null);
	};

	return (
		<>
			{quick && (
				<Table withRowBorders={false}>
					<Table.Tbody>
						{templates
							?.filter((item) => item.destination == 'ai')
							?.map((item, index) => (
								<Table.Tr key={index}>
									<Table.Td>{item.name}</Table.Td>

									<Table.Td>
										<Tooltip
											label="Replace selected text in editor"
											zIndex={10000}
										>
											<ActionIcon
												aria-label="Settings"
												disabled={loading}
												loading={
													!loading?.action && loading?.template_id == item.id
												}
												onClick={() =>
													onSubmit({
														input: selected_text,
														template_id: item.id,
													})
												}
												variant="light"
											>
												<IconPencil size="1rem" stroke={1.5} />
											</ActionIcon>
										</Tooltip>
									</Table.Td>

									<Table.Td>
										<Tooltip
											label="Insert before selected text in editor"
											zIndex={10000}
										>
											<ActionIcon
												aria-label="Settings"
												disabled={loading}
												loading={
													loading?.action == 'insert_before' &&
													loading?.template_id == item.id
												}
												onClick={() =>
													onSubmit({
														action: 'insert_before',
														input: selected_text,
														template_id: item.id,
													})
												}
												variant="light"
											>
												<IconRowInsertTop size="1rem" stroke={1.5} />
											</ActionIcon>
										</Tooltip>
									</Table.Td>

									<Table.Td>
										<Tooltip
											label="Insert after selected text in editor"
											zIndex={10000}
										>
											<ActionIcon
												aria-label="Settings"
												disabled={loading}
												loading={
													loading?.action == 'insert_after' &&
													loading?.template_id == item.id
												}
												onClick={() =>
													onSubmit({
														action: 'insert_after',
														input: selected_text,
														template_id: item.id,
													})
												}
												variant="light"
											>
												<IconRowInsertBottom size="1rem" stroke={1.5} />
											</ActionIcon>
										</Tooltip>
									</Table.Td>

									<Table.Td>
										<Tooltip
											label="Show result in external window"
											zIndex={10000}
										>
											<ActionIcon
												aria-label="Settings"
												disabled={loading}
												loading={
													loading?.action == 'modal' &&
													loading?.template_id == item.id
												}
												onClick={() =>
													onSubmit({
														action: 'modal',
														input: selected_text,
														template_id: item.id,
													})
												}
												variant="light"
											>
												<IconExternalLink size="1rem" stroke={1.5} />
											</ActionIcon>
										</Tooltip>
									</Table.Td>
								</Table.Tr>
							))}
					</Table.Tbody>
				</Table>
			)}

			{!quick && (
				<Popover
					opened={popupOpened}
					position="bottom"
					shadow="md"
					trapFocus
					width={300}
					withArrow
					withinPortal
				>
					<Popover.Target>
						<RichTextEditor.Control
							aria-label="AI content"
							onClick={popupOpened ? popupClose : popupOpen}
							title="AI content"
						>
							<IconAi size="1rem" stroke={1.5} />
						</RichTextEditor.Control>
					</Popover.Target>
					<Popover.Dropdown>
						<Form hasForm={false} methods={formMethods}>
							<Stack gap="xs">
								<Select
									data={templates
										?.filter((item) => item.destination == 'ai')
										?.map((item) => ({
											label: item.name,
											value: `${item.id}`,
										}))}
									name="template_id"
									placeholder="Template id"
								/>
								<Textarea
									defaultValue={selected_text}
									name="input"
									placeholder="Input"
									rows={5}
								/>
								<Submit
									name="Get"
									onClick={formMethods.handleSubmit(onSubmit)}
									type="button"
								/>
							</Stack>
						</Form>
					</Popover.Dropdown>
				</Popover>
			)}

			<Drawer
				onClose={drawerClose}
				opened={drawerOpened}
				position="right"
				size="md"
				title="Result"
			>
				<DrawerAi
					data={drawerData?.data}
					template_id={drawerData?.template_id}
				/>
			</Drawer>
		</>
	);
};

const InsertTextControl = ({ templates }) => {
	const { language } = useContext(AdminContext);
	const [popupOpened, { close: popupClose, open: popupOpen }] =
		useDisclosure(false);
	const { editor } = useRichTextEditorContext();

	const onSubmit = async (template_id) => {
		const template = templates.find((item) => item.id == template_id);

		if (template) {
			const template_variant = template.template_variants.find(
				(item) => item.language_id == language.id,
			);

			if (template_variant) {
				editor?.commands.insertContent(template_variant.content);
			}
		}

		popupClose();
	};

	return (
		<Popover
			opened={popupOpened}
			position="bottom"
			shadow="md"
			trapFocus
			width={300}
			withArrow
			withinPortal
		>
			<Popover.Target>
				<RichTextEditor.Control
					aria-label="Text templates"
					onClick={popupOpened ? popupClose : popupOpen}
					title="Text templates"
				>
					<IconTemplate size="1rem" stroke={1.5} />
				</RichTextEditor.Control>
			</Popover.Target>
			<Popover.Dropdown>
				<Select
					data={templates
						?.filter(
							(item) =>
								item?.template_variants?.find(
									(check) => check.language_id == language.id,
								) && item.destination == 'text',
						)
						?.map((item) => ({
							label: item.name,
							value: `${item.id}`,
						}))}
					name="template_id"
					onChange={onSubmit}
					placeholder="Template id"
				/>
			</Popover.Dropdown>
		</Popover>
	);
};

const InsertTextSmallControl = () => {
	const { editor } = useRichTextEditorContext();

	const handleClick = () => {
		editor.commands.toggleSmall();
	};

	return (
		<RichTextEditor.Control
			aria-label="Small"
			onClick={handleClick}
			title="Small"
		>
			<IconAlphabetLatin size="1rem" stroke={1.5} />
		</RichTextEditor.Control>
	);
};

const InsertImageControl = () => {
	const [opened, { close, open }] = useDisclosure(false);
	const formMethods = useForm();
	const { editor } = useRichTextEditorContext();

	const onSubmit = ({ url }) => {
		if (url === null) return;
		editor.commands.setImage({ src: url });
		close();
	};

	return (
		<Popover
			onClose={close}
			opened={opened}
			position="bottom"
			shadow="md"
			trapFocus
			width={300}
			withArrow
			withinPortal
		>
			<Popover.Target>
				<RichTextEditor.Control
					aria-label="Insert image"
					onClick={opened ? close : open}
					title="Insert image"
				>
					<IconPhoto size="1rem" stroke={1.5} />
				</RichTextEditor.Control>
			</Popover.Target>
			<Popover.Dropdown>
				<Form hasForm={false} methods={formMethods}>
					<Stack gap="xs">
						<Input name="url" placeholder="Url" type="url" />
						<Submit
							name="Add"
							onClick={formMethods.handleSubmit(onSubmit)}
							type="button"
						/>
					</Stack>
				</Form>
			</Popover.Dropdown>
		</Popover>
	);
};

const InsertLanguageControl = () => {
	const { language } = useContext(AdminContext);

	return (
		<Language>
			<RichTextEditor.Control
				aria-label="Switch language"
				title="Switch language"
			>
				<Text size="0.5rem">{language.symbol.toUpperCase()}</Text>
			</RichTextEditor.Control>
		</Language>
	);
};

const InsertTagControl = () => {
	const [opened, { close, open }] = useDisclosure(false);
	const formMethods = useForm();
	const { editor } = useRichTextEditorContext();

	const options = {
		allergen_id: 'Allergen',
		article_id: 'Article',
		marker_id: 'Marker',
		product_id: 'Product',
	};
	const previous = editor.getAttributes('tag');
	let previous_option = null;

	for (const key in previous) {
		if (!options[key] || !previous[key]) {
			continue;
		}
		previous_option = key;
		break;
	}

	const onSubmit = ({ id, title, type }) => {
		editor.commands.setTag({ title, [type]: id });
		close();
	};

	return (
		<Popover
			opened={opened}
			position="bottom"
			shadow="md"
			trapFocus
			width={300}
			withArrow
			withinPortal
		>
			<Popover.Target>
				<RichTextEditor.Control
					aria-label="Insert tag"
					onClick={opened ? close : open}
					title="Insert tag"
				>
					<IconTag size="1rem" stroke={1.5} />
				</RichTextEditor.Control>
			</Popover.Target>
			<Popover.Dropdown>
				<Form hasForm={false} methods={formMethods}>
					<Stack gap="xs">
						<Select
							data={Object.keys(options).map((key) => ({
								label: options[key],
								value: key,
							}))}
							defaultValue={Object.keys(options)
								.filter((key) => key == previous_option)
								.map((key) => ({
									label: options[key],
									value: key,
								}))
								.at(0)}
							name="type"
							placeholder="Type"
							required
						/>
						<Input
							defaultValue={previous_option ? previous[previous_option] : null}
							name="id"
							placeholder="Id"
							required
							type="number"
						/>
						<Input
							defaultValue={previous?.title}
							name="title"
							placeholder="Title"
							type="text"
						/>

						<Submit
							name="Save"
							onClick={formMethods.handleSubmit(onSubmit)}
							type="button"
						/>
					</Stack>
				</Form>
			</Popover.Dropdown>
		</Popover>
	);
};

const SwitchEditor = ({ format, setType, type }) => {
	return (
		<>
			{type == 'editor' && (
				<RichTextEditor.Control
					aria-label="Switch editor"
					onClick={() => setType('html')}
					title="Switch editor"
				>
					<IconSourceCode size="1rem" stroke={1.5} />
				</RichTextEditor.Control>
			)}

			{type == 'html' && (
				<ActionIcon onClick={() => setType('editor')} variant="transparent">
					{format == 'markdown' ? (
						<IconMarkdown size="1rem" stroke={1.5} />
					) : (
						<IconHtml size="1rem" stroke={1.5} />
					)}
				</ActionIcon>
			)}
		</>
	);
};

DrawerAi.propTypes = {
	data: PropTypes.object,
	template_id: PropTypes.number,
};

Editor.propTypes = {
	counter: PropTypes.bool,
	defaultType: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	description: PropTypes.string,
	format: PropTypes.string,
	max: PropTypes.number,
	name: PropTypes.string,
	options: PropTypes.object,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	rows: PropTypes.number,
};

EditorBubbleMenu.propTypes = {
	editor: PropTypes.object,
	options: PropTypes.object,
	templates: PropTypes.array,
};

EditorHandler.propTypes = {
	defaultType: PropTypes.string,
	description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	error: PropTypes.string,
	format: PropTypes.string,
	max: PropTypes.number,
	name: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	options: PropTypes.object,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	rows: PropTypes.number,
	value: PropTypes.string,
};

EditorToolbar.propTypes = {
	format: PropTypes.string,
	options: PropTypes.object,
	setType: PropTypes.func,
	templates: PropTypes.array,
	type: PropTypes.string,
};

InsertAIControl.propTypes = {
	options: PropTypes.object,
	quick: PropTypes.bool,
	templates: PropTypes.array,
};

InsertTextControl.propTypes = {
	templates: PropTypes.array,
};

SwitchEditor.propTypes = {
	format: PropTypes.string,
	setType: PropTypes.func,
	type: PropTypes.string,
};

export default Editor;
