import { ActionIcon, Card, Group, Menu, Modal, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	IconChevronDown,
	IconChevronUp,
	IconDotsVertical,
	IconGripVertical,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const ActionCard = ({
	children,
	hasFullscreen = false,
	hasHeader = true,
	id,
	isMoveable = false,
	isOpened = true,
	isTogglable = true,
	leftSection,
	rightSection,
	...props
}) => {
	const [openedCard, { toggle: toggleCard }] = useDisclosure(
		hasHeader && isTogglable ? isOpened : true,
	);
	const [openedModal, { close: closeModal, open: openModal }] =
		useDisclosure(false);

	const menuItems = useMemo(() => {
		let out = [];

		if (hasFullscreen) {
			out.push({
				children: 'Fullscreen',
				properties: {
					onClick: openModal,
				},
			});
		}

		return out;
	}, [hasFullscreen, openModal]);

	return (
		<>
			<Card
				mb="md"
				padding={children && openedCard ? 'md' : ''}
				radius="md"
				shadow="sm"
				withBorder
				{...props}
			>
				{hasHeader && (
					<Card.Section
						mb={children && openedCard ? 'sm' : ''}
						px="xs"
						py="xs"
						withBorder
					>
						<Group justify="space-between" wrap="nowrap">
							<Group gap="xs" wrap="nowrap">
								{isMoveable && (
									<ActionIcon color="gray" size="xs" variant="transparent">
										<IconGripVertical size={20} />
									</ActionIcon>
								)}
								{leftSection && <>{leftSection}</>}
							</Group>
							<Group justify="flex-end" wrap="nowrap">
								{rightSection && <>{rightSection}</>}

								{menuItems?.length > 0 && (
									<Menu shadow="md">
										<Menu.Target>
											<ActionIcon color="gray" size="xs" variant="transparent">
												<Tooltip label="Menu">
													<IconDotsVertical size={20} />
												</Tooltip>
											</ActionIcon>
										</Menu.Target>

										<Menu.Dropdown>
											{menuItems.map((item, index) => {
												return (
													<Menu.Item key={index} {...item.properties}>
														{item.children}
													</Menu.Item>
												);
											})}
										</Menu.Dropdown>
									</Menu>
								)}

								{isTogglable && (
									<ActionIcon
										color="gray"
										onClick={toggleCard}
										size="xs"
										variant="transparent"
									>
										{openedCard ? (
											<Tooltip label="Collapse">
												<IconChevronUp size={20} />
											</Tooltip>
										) : (
											<Tooltip label="Expand">
												<IconChevronDown size={20} />
											</Tooltip>
										)}
									</ActionIcon>
								)}
							</Group>
						</Group>
					</Card.Section>
				)}

				{openedCard && <>{children}</>}
			</Card>

			{menuItems?.length > 0 && openedModal && (
				<Modal onClose={closeModal} opened={openedModal} size="xl">
					{children}
				</Modal>
			)}
		</>
	);
};

ActionCard.propTypes = {
	children: PropTypes.node,
	hasFullscreen: PropTypes.bool,
	hasHeader: PropTypes.bool,
	id: PropTypes.string,
	isMoveable: PropTypes.bool,
	isOpened: PropTypes.bool,
	isTogglable: PropTypes.bool,
	leftSection: PropTypes.node,
	rightSection: PropTypes.node,
};

export default ActionCard;
